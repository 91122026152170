/** -----------------------------------------------------------------------
 * CMS GraphQL Service
 * Fetches Content from the AT CMS
 * 
 * Cache Adapter: https://www.npmjs.com/package/axios-cache-adapter
 * ------------------------------------------------------------------------ */

  
export default class GraphCMS{
    constructor(){
        const cmsUrl = process.env.NEXT_PUBLIC_cms_cache_url || process.env.NEXT_PUBLIC_cms_url;
        this.baseUrl = `${cmsUrl}/api`;
        this.settings = {
            cache: 'force-cache',
            headers: {
                'Content-Type': 'application/graphql',
                'Authorization': `Bearer ${process.env.NEXT_PUBLIC_graphql_cms_token}`
            }
        };
    }


    async get(queryParams){
        const searchParams = new URLSearchParams(queryParams);

        return await fetch(`${this.baseUrl}` + (searchParams && `?${searchParams}`), {
                ...this.settings,
                method: "GET",
            })
            .then(response => response.text())
            .then(result => JSON.parse(result))
            .catch(err => console.log(err))
    }



    async post(data, key){
        const searchParams = new URLSearchParams(key);

        return await fetch(`${this.baseUrl}` + (searchParams && `?${searchParams}`), {
                ...this.settings,
                method: "POST",
                body: data
            })
            .then(response => response.text())
            .then(result =>{
                return JSON.parse(result)
            })
            .catch(err => console.log(err))
    }


}